import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import $ from 'jquery'
import _ from 'lodash'

// UTILS
import * as AssignDriversUtils from 'utils/common/assignDrivers'
import { Utils } from 'utils/Utils'
import {
  isCustomerEditBooking,
  currentVehicleType as currentVehicleTypeUtils,
  minuMumBufferMinutes,
} from 'utils/new_booking/common'
import I18n from 'i18n/i18n'
// ACTIONS
import * as bookingActionCreators from 'store/actions/common/bookingActionCreators'
import * as timeTypeActionCreators from 'store/actions/new_booking/timeTypeActionCreators'
import * as calendarBookingActionCreators from 'store/actions/common/calendarActionCreators'
import * as stepActionCreators from 'store/actions/new_booking/stepActionCreators'
import * as assignDriverActionCreators from 'store/actions/new_booking/assignDriverActionCreators'

// COMPONENTS
import ListAssignableDriversPopup from './ListAssignableDriversPopup'
import DriverPreferencesPopupEye from '../../common/popup/driver_preferences/DriverPreferencesPopupEye'
import AssignableDriver from '../../common/driver_preference/AssignableDriver'
import DriverPreferenceButton from '../../common/driver_preference/DriverPreferenceButton'
import DynamicPopupContent from '../../common/driver_preference/DynamicPopupContent'
import ModalDecision from '../../common/driver_preference/ModalDecision'
import BookingCalendar from '../../common/BookingCalendar'
// CONSTANTS
import {
  DRIVER_PREFERENCES,
  NOW,
  SCHEDULE
} from 'constants/newBookingConstants'
import {
  POPUP_ID_ASSIGN_DRIVERS,
  POPUP_ID_SWITCH_TAB_DRIVER_PREFERENCE,
  POPUP_ID_SWITCH_TO_SCHEDULE,
  SHOW_DATE_PICKER_BY_BOOKING
} from 'constants/common/popupConstants'
import {
  ICON_AVATAR_FLEET_PARTNER_ASSIGN_DRIVER,
  FAVORITE_GRAY_ICON,
  ICON_DRIVER_PREFERENCES_FULL,
  ICON_SWITCH_TAB_POPUP
} from 'constants/imageConstants'
// ASSETS
// API
import PopupAPI from 'api/popups'
import { getQueryVariable, hasAssignDriver } from 'utils/booking/common'
import { othersActionsCreator } from 'store/toolkit/newBooking/others.reducer'
import { quickChoiceActionsCreator } from 'store/toolkit/newBooking/quickChoice/quickChoice.reducer'
import { showDatePickerActionsCreator } from 'store/toolkit/newBooking/showDatePicker.reducer'
import { timeTypeUIActionsCreator } from 'store/toolkit/newBooking/timeTypeUI.reducer'
import { currentPopupIDActionsCreator } from 'store/toolkit/currentPopupID/currentPopupID.reducer'
import { pickupTimeActionsCreator } from 'store/toolkit/newBooking/pickupTime.reducer'
import CommonUtils from 'utils/common'

const mapStateToProps = state => ({
  booking: state.booking,
  others: state.others,
  extraInfos: state.extraInfos,
  currentCustomer: state.currentCustomer,
  timeType: state.timeType,
  currentStep: state.currentStep,
  extraServices: state.extraServices,
  locations: state.locations,
  isNewDriver: state.isNewDriver,
  pickupTime: state.pickupTime,
  assignedPickupTime: state.assignedPickupTime,
  tmpSelectedDateAssignDriver: state.tmpSelectedDateAssignDriver,
  assignedDriver: state.assignedDriver,
  bookAgainDetails: state.bookAgainDetails,
  extraRequirements: state.extraRequirements,
  currentVehicleType: currentVehicleTypeUtils(state),
  currentPopupID: state.currentPopupID,
  showDatePicker: state.showDatePicker,
  checkLocations: state.checkLocations,
})

function mapDispatchToProps(dispatch) {
  return {
    OthersAction: bindActionCreators(othersActionsCreator, dispatch),
    stepActions: bindActionCreators(stepActionCreators, dispatch),
    assignDriverActions: bindActionCreators(assignDriverActionCreators, dispatch),
    bookingActions: bindActionCreators(bookingActionCreators, dispatch),
    currentPopupIDActions: bindActionCreators(currentPopupIDActionsCreator, dispatch),
    timeTypeActions: bindActionCreators(timeTypeActionCreators, dispatch),
    pickupTimeActions: bindActionCreators(pickupTimeActionsCreator, dispatch),
    calendarBookingActions: bindActionCreators(calendarBookingActionCreators, dispatch),
    quickChoiceActions: bindActionCreators(quickChoiceActionsCreator, dispatch),
    datePickerActions: bindActionCreators(showDatePickerActionsCreator, dispatch),
    timeTypeUIActions: bindActionCreators(timeTypeUIActionsCreator, dispatch)
  }
}

class DriverPreferences extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentSelected: props.booking?.driverPreferencesTab, // Use currentSelected as temporary param, should use driverPreferencesTab instead, will refactor when we have time
      previousCurrentSelected: DRIVER_PREFERENCES.all,
      popupConst: POPUP_ID_ASSIGN_DRIVERS.popup.favorite_active,
      popupData: {},
      assignableDrivers: [],
      isShowPopup: false,
    }
    this.tempTabSelected = ''
    this.renderDynamicContent = this.renderDynamicContent.bind(this)
    this.handleGoBack = this.handleGoBack.bind(this)
    this.resetShowPopup = this.resetShowPopup.bind(this)
    this.handleConfirmSwitchTab = this.handleConfirmSwitchTab.bind(this)
    this.changeTabNowTimeType = this.changeTabNowTimeType.bind(this)
    this.closePicker = this.closePicker.bind(this)
    this.calledAPIAfterSignIn = false
    this.getParamsForLoadFavorite = this.getParamsForLoadFavorite.bind(this)
  }

  componentDidMount() {
    const { currentCustomer } = this.props
    this.calledAPIAfterSignIn = !!currentCustomer.authentication_token
    this.handleSetDefaultCurrentSelected()
  }

  componentWillReceiveProps(nextProps) {
    const { assignedDriver, others } = this.props
    if (nextProps.assignedDriver !== assignedDriver && _.isNull(nextProps.assignedDriver)) {
      this.setSelectedButtonByDefaultInfo(others, nextProps.timeType, nextProps.assignedDriver)
    }
    if (!_.isEmpty(nextProps.assignedDriver)) {
      this.setState({
        currentSelected: DRIVER_PREFERENCES.assign,
      })
    } else if (window.setSendBookingToAllDrivers) {
      window.setSendBookingToAllDrivers = undefined
      this.handleChangePreferencesType(DRIVER_PREFERENCES.all)
      this.setState({ isShowPopup: false })
    } else if (window.addToFavoriteDrivers) {
      window.setSendBookingToAllDrivers = undefined
      this.getFavoriteAmount()
      this.loadFavoriteDrivers()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentSelected } = this.state
    const { currentSelected: prevCurrentSelected } = prevState
    const { currentCustomer, booking, bookingActions } = this.props
    // get list drivers after sign in successfully
    if (currentCustomer.authentication_token
      && currentCustomer.area_id
      && booking.isSignInFinished
      && !this.calledAPIAfterSignIn) {
      if (currentSelected === DRIVER_PREFERENCES.favorite) {
        this.loadFavoriteDrivers(null, true)
      }

      if (currentSelected === DRIVER_PREFERENCES.assign) {
        this.loadAssignDrivers(true, true, true)
      }

      this.getFavoriteAmount()

      this.calledAPIAfterSignIn = true
    }

    if (currentSelected !== prevCurrentSelected) {
      bookingActions.updateBookingAttributes({ driverPreferencesTab: currentSelected })
    }
  }

  getFavoriteAmount(cb) {
    const {
      currentCustomer,
      selectedVehicleTypeID,
      bookingActions,
      extraInfos,
    } = this.props

    const params = {
      include_fleet_driver: true,
      vehicle_type_id: selectedVehicleTypeID,
      area_id: currentCustomer.area_id,
      country_code: extraInfos.country_code,
      company_id: currentCustomer.currentCompanyId === 0 ? undefined : currentCustomer.currentCompanyId,
    }

    bookingActions.getFavoriteAmount(params, cb)
  }

  getPopupApi(itemType) {
    const { area, currentCustomer } = this.props
    const params = {
      area_id: area.id,
      popup_type: itemType,
    }
    return new Promise((resolve) => {
      PopupAPI.getAreaTypePopup(currentCustomer?.authentication_token, params, (result) => {
        const popupdnd = result?.data?.object
        if (result?.status === 200 && !_.isEmpty(popupdnd)) {
          this.setState({
            popupData: popupdnd
          })
        }
        resolve()
      })
    })
  }

  setSelectedButtonByDefaultInfo(others, timeType, nextPropsAssignedDriver = null, isGoBack = false) {
    const {
      bookAgainDetails,
      assignedDriver,
      extraInfos,
      booking,
    } = this.props
    const { isShowPopup } = this.state
    const reachingFavoriteAmount = others.favoriteDriversAmount >= extraInfos.minimal_drivers_setting
    if (timeType === NOW) {
      this.setState({
        currentSelected: DRIVER_PREFERENCES.all,
      })
    } else if (!_.isEmpty(bookAgainDetails) && _.isEmpty(assignedDriver)) {
      this.setState({
        currentSelected: bookAgainDetails.send_first_to_favorite ? DRIVER_PREFERENCES.favorite : DRIVER_PREFERENCES.all,
      })
    } else if (reachingFavoriteAmount && !isGoBack && !isShowPopup) {
      this.setState({
        currentSelected: DRIVER_PREFERENCES.favorite,
      })
    } else if (booking?.driverPreferencesTab !== DRIVER_PREFERENCES.all
        && _.isEmpty(nextPropsAssignedDriver) && !isShowPopup) {
      const selectedStatus = ((bookAgainDetails && bookAgainDetails.send_first_to_favorite) || reachingFavoriteAmount)
        ? DRIVER_PREFERENCES.favorite : DRIVER_PREFERENCES.all
      this.setState({
        currentSelected: selectedStatus,
      })
    }
  }

  setCurrentSelected(driverPreferencesTab) {
    this.setState({ currentSelected: driverPreferencesTab })
  }

  handleSetDefaultCurrentSelected() {
    const {
      others, assignedDriver, extraInfos, booking,
      timeType, bookAgainDetails, isNewDriver, OthersAction,
    } = this.props
    const { driverPreferencesTab } = booking
    OthersAction.updateOthers({ attrs: { isInitSuccess: true } })
    if (isNewDriver && !others.isInitSuccess) {
      this.loadAssignDrivers(true)
      $(this.driverPreferences).get(0).scrollIntoView()
      return
    }
    let defaultTab = driverPreferencesTab || DRIVER_PREFERENCES.all
    if (!driverPreferencesTab && extraInfos?.send_favorite_driver_first
      && +others?.favoriteDriversAmount >= +extraInfos.minimal_drivers_setting) {
      defaultTab = DRIVER_PREFERENCES.favorite
    }
    if (timeType === NOW) return this.setCurrentSelected(DRIVER_PREFERENCES.all)
  
    if (!_.isEmpty(assignedDriver)) return this.setCurrentSelected(DRIVER_PREFERENCES.assign)

    // driverPreferencesTab === DRIVER_PREFERENCES.assign and isEmpty(assignedDriver) or no change data when back to step 1
    if ((driverPreferencesTab === DRIVER_PREFERENCES.assign || !driverPreferencesTab)
      && (others?.favoriteDriversAmount >= extraInfos.minimal_drivers_setting || bookAgainDetails.send_first_to_favorite)) {
      defaultTab = DRIVER_PREFERENCES.favorite
    }

    if (defaultTab === DRIVER_PREFERENCES.assign && _.isEmpty(assignedDriver)) defaultTab = DRIVER_PREFERENCES.all

    this.setCurrentSelected(defaultTab)
  }

  getParamsForLoadFavorite(loadingMore = true) {
    return CommonUtils.getParamsForLoadFavoriteCommon(this.props, loadingMore)
  }

  popupActionUpdateState(output) {
    this.setState({ popupConst: output })
  }

  showSwitchTabConfirm(value) {
    const { currentSelected } = this.state
    const { currentPopupIDActions } = this.props

    if (currentSelected === DRIVER_PREFERENCES.assign) {
      currentPopupIDActions.updateCurrentPopupID(POPUP_ID_SWITCH_TAB_DRIVER_PREFERENCE)
    } else {
      this.switchToAnotherTab(value)
    }
  }

  switchToAnotherTab(value) {
    const {
      others,
      assignDriverActions,
      currentCustomer
    } = this.props
    const isSendFavorite = value === DRIVER_PREFERENCES.favorite

    if (_.isEmpty(currentCustomer.authentication_token)
      && [DRIVER_PREFERENCES.favorite, DRIVER_PREFERENCES.assign].includes(value)) {
      this.setState({ isShowPopup: true, currentSelected: value })
      return
    }

    if (value === DRIVER_PREFERENCES.assign) {
      this.loadAssignDrivers(true, false)
    }

    if (isSendFavorite && !others.favoriteDriversAmount) {
      this.loadFavoriteDrivers()
    }

    Promise.resolve(
      assignDriverActions.changeAssignedPickupTime(''),
      assignDriverActions.changeAssignDriver(null)
    ).then(() => {
      this.setState({
        currentSelected: value,
      }, () => {
        if (value === DRIVER_PREFERENCES.favorite) {
          window.Moengage.track_event('Favorite Drivers Selected')
        }
      })
    })
  }

  handleChangePreferencesType(value, callAPI = true, updatePrevSelected = true) {
    const {
      others,
      timeType,
      currentCustomer,
      refNode,
      currentPopupIDActions
    } = this.props
    const renderNode = refNode.current
    this.tempTabSelected = value
    $(renderNode).removeClass('visible')
    const currentState = AssignDriversUtils.noticePopupStatus({
      value,
      timeType,
      currentCustomer,
      favoriteDriversAmount: others.favoriteDriversAmount,
    })

    if (_.isEmpty(currentCustomer.authentication_token)
      && [DRIVER_PREFERENCES.favorite, DRIVER_PREFERENCES.assign].includes(value)
      && timeType !== NOW) {
      this.setState({ isShowPopup: true, currentSelected: value })
      return
    }

    if (_.isUndefined(currentState)) {
      const isAssigning = value === DRIVER_PREFERENCES.assign
      if (isAssigning) {
        this.loadAssignDrivers(callAPI, updatePrevSelected)
      } else {
        this.showSwitchTabConfirm(value)
      }
    } else {
      currentPopupIDActions.updateCurrentPopupID(POPUP_ID_SWITCH_TO_SCHEDULE)
    }
  }

  callBackAfterGetAssignableDrivers(result, updatePrevSelected = true, afterSignIn) {
    const { stepActions, assignDriverActions } = this.props
    const { currentSelected, previousCurrentSelected } = this.state
    const prevSelected = updatePrevSelected ? currentSelected : previousCurrentSelected
    const assignableDrivers = _.isEmpty(result?.data.data) ? [] : result.data.data
    const updateAssignableDrivers = {
      assignableDrivers,
      currentSelected: DRIVER_PREFERENCES.assign,
    }

    if (hasAssignDriver() && assignableDrivers.length > 0) {
      const driverId = +getQueryVariable('driver_id')
      const assignableDriver = assignableDrivers.find(driver => driver.id === driverId
        || driver.fleet_partner_id === driverId)
      assignDriverActions.changeAssignDriver(assignableDriver)
    }
    if (!afterSignIn) {
      _.assign(updateAssignableDrivers, {
        isShowPopup: true,
      })
    }

    if (prevSelected !== DRIVER_PREFERENCES.assign) {
      _.assign(updateAssignableDrivers, {
        previousCurrentSelected: prevSelected,
      })
    }
    this.setState(updateAssignableDrivers, () => {
      stepActions.loaded()
    })
  }

  actionWithoutCallAPI() {
    const { stepActions, } = this.props
    const { currentSelected } = this.state
    const updateAssignableDrivers = {
      currentSelected: DRIVER_PREFERENCES.assign,
      isShowPopup: true,
    }
    if (currentSelected !== DRIVER_PREFERENCES.assign) {
      _.assign(updateAssignableDrivers, {
        previousCurrentSelected: currentSelected,
      })
    }
    this.setState(updateAssignableDrivers, () => {
      stepActions.loaded()
    })
  }

  loadAssignDrivers(callAPI, updatePrevSelected = true, afterSignIn = false) {
    const {
      stepActions,
    } = this.props
    const { assignableDrivers } = this.state
    stepActions.loading()

    if (callAPI || _.isEmpty(assignableDrivers)) {
      this.loadFavoriteDrivers((response) => {
        this.callBackAfterGetAssignableDrivers(response, updatePrevSelected, afterSignIn)
      })
    } else {
      this.actionWithoutCallAPI()
    }
  }


  loadFavoriteDrivers(cb, afterSignIn = false) {
    const {
      stepActions,
      bookingActions,
    } = this.props

    const params = this.getParamsForLoadFavorite(false)

    stepActions.loading()

    bookingActions.loadFavoriteDrivers(params, stepActions, (response) => {
      if (cb) {
        cb(response)
      } else if (!afterSignIn) {
        this.setState({
          isShowPopup: true
        })
      }
    })
  }

  handleGoBack() {
    const {
      assignedDriver,
      timeType,
      others,
      extraInfos
    } = this.props
    if (assignedDriver) {
      this.setSelectedButtonByDefaultInfo(others, timeType, null, true)
    } else {
      const { currentSelected } = this.state
      const reachingFavoriteAmount = others.favoriteDriversAmount >= extraInfos.minimal_drivers_setting

      if (currentSelected === DRIVER_PREFERENCES.assign) {
        this.setState({
          currentSelected: reachingFavoriteAmount ? DRIVER_PREFERENCES.favorite : DRIVER_PREFERENCES.all,
        })
      }

      if (currentSelected === DRIVER_PREFERENCES.favorite) {
        this.setState({
          currentSelected: others.favoriteDriversAmount ? DRIVER_PREFERENCES.favorite : DRIVER_PREFERENCES.all
        })
      }
    }
    this.resetShowPopup()
  }

  resetShowPopup(driver) {
    this.setState({
      isShowPopup: false
    })
    if (_.isObject(driver)) {
      const { assignableDrivers } = this.state
      if (driver.fleet_partner_id) {
        const existedAvatarDriver = _.find(assignableDrivers, ['fleet_partner_id', driver.fleet_partner_id])

        if (existedAvatarDriver) {
          existedAvatarDriver.id = driver.id
          this.setState({ assignableDrivers })
        }
      } else if (_.findIndex(assignableDrivers, ['id', driver.id]) === -1) {
        this.setState(prevState => ({
          assignableDrivers: [...prevState.assignableDrivers, driver]
        }))
      }
    }
  }

  handleConfirmSwitchTab() {
    const { currentPopupIDActions } = this.props
    this.switchToAnotherTab(this.tempTabSelected)
    currentPopupIDActions.updateCurrentPopupID('')
  }

  changeTabNowTimeType() {
    const {
      currentVehicleType,
      pickupTime,
      timeTypeActions,
      calendarBookingActions,
      currentPopupIDActions,
      quickChoiceActions,
      datePickerActions,
      timeTypeUIActions,
    } = this.props

    const minimumPickupTime = minuMumBufferMinutes(currentVehicleType, SCHEDULE, 0)
    const pickupTimeToMinutes = Utils.convertPickupTimeToMinutes(pickupTime)
    const isValidPickupTime = pickupTimeToMinutes > minimumPickupTime

    if (isValidPickupTime) {
      Promise.resolve(
        timeTypeActions.actionChangeTimeType(SCHEDULE),
        timeTypeUIActions.changeTimeTypeUI(SCHEDULE),
        quickChoiceActions.changeQuickChoice(0)
      ).then(() => {
        this.switchToAnotherTab(this.tempTabSelected)
      })
    }

    if (!isValidPickupTime) {
      datePickerActions.showDatePicker(true)
      calendarBookingActions.add(`${SHOW_DATE_PICKER_BY_BOOKING}-SINGLE`)
    }

    currentPopupIDActions.updateCurrentPopupID('')
  }

  closePicker(cancel, selectedDateTime) {
    const {
      calendarBookingActions,
      timeTypeActions,
      timeTypeUIActions,
      quickChoiceActions,
      pickupTimeActions,
      datePickerActions
    } = this.props

    if (!cancel) {
      Promise.resolve(
        timeTypeActions.actionChangeTimeType(SCHEDULE),
        timeTypeUIActions.changeTimeTypeUI(SCHEDULE),
        pickupTimeActions.changePickupTime(selectedDateTime),
        quickChoiceActions.changeQuickChoice(0),
      ).then(() => {
        this.switchToAnotherTab(this.tempTabSelected)
      })
    }

    datePickerActions.showDatePicker(false)
    calendarBookingActions.remove(`${SHOW_DATE_PICKER_BY_BOOKING}-SINGLE`)
  }

  renderDynamicContent() {
    const { popupData, popupConst } = this.state
    return (
      <DynamicPopupContent
        popupData={popupData}
        popupConst={popupConst}
      />
    )
  }

  renderSelectedDriverInfo(selectedDriver) {
    const {
      pickupTime, assignedPickupTime, extraInfos,
    } = this.props
    const driverAvatar = selectedDriver.fleet_partner_id
      ? ICON_AVATAR_FLEET_PARTNER_ASSIGN_DRIVER : selectedDriver.driver_image_url
    const driverName = selectedDriver.fleet_partner_name || Utils.driverFirstName(selectedDriver.name)
    const formatTimeAssign = Utils.formatDateTime(assignedPickupTime, extraInfos.country_code)
    const currentVehicle = selectedDriver.current_vehicle || selectedDriver.vehicles[0]
    let vehicleTypeName = ''
    if (!!currentVehicle && !!currentVehicle.vehicle_type_name) {
      vehicleTypeName = currentVehicle.vehicle_type_name
    }
    let driverPlateNumber = I18n.t('webapp.new_booking.step_2.assign_driver.fleet_plate_number')
    if (!!currentVehicle && !!currentVehicle.vehicle_attributes && _.isNull(selectedDriver.fleet_partner_id)) {
      driverPlateNumber = currentVehicle.vehicle_attributes.plate_number
    }
    let renderPickupTime
    if (!_.isEmpty(assignedPickupTime) && pickupTime !== assignedPickupTime) {
      renderPickupTime = () => (
        <p className="default-font Green-text reset m">
          {`${I18n.t('batches.label.pickup')}: ${formatTimeAssign.fullTimeDate}`}
        </p>
      )
    }
    return (
      <div className="ml10 mr10 mar20 Driver-Preferences-Container">
        <AssignableDriver
          specialClass="cur-pointer"
          onClickHandler={() => this.handleChangePreferencesType(DRIVER_PREFERENCES.assign, false)}
          driverInfos={{
            avatar: {
              url: driverAvatar,
              size: 'Big'
            },
            name: driverName,
            pickupTime: renderPickupTime,
            vehicleTypeName,
            driverPlateNumber
          }}
          isFleet={selectedDriver.fleet_partner_id}
        >
          <div className="Popover-Item-Driver-Item-Actions flex flex-center">
            <button
              type="button"
              className="Button Dark-Light-Gray-bg capitalize Border-Default White-text Black-Gray-text-hover"
            >
              {I18n.t('webapp.label.change')}
            </button>
          </div>
        </AssignableDriver>

        {
          !selectedDriver.is_favorite_driver ? (
            <p className="explain-text">
              {I18n.t('webapp.label.add_driver_favorite_list')}
            </p>
          ) : null
        }
      </div>
    )
  }

  renderFavoriteText() {
    const { currentSelected } = this.state
    const { others: { favoriteDriversAmount } } = this.props

    if (currentSelected === DRIVER_PREFERENCES.favorite && favoriteDriversAmount) {
      const textFavDriver = favoriteDriversAmount > 1
        ? I18n.t('webapp.label.eligible_drivers')
        : I18n.t('webapp.label.eligible_driver')

      return (
        <div className="favorite-driver-text">
          <img src={FAVORITE_GRAY_ICON} alt="favorite icon" />
          {I18n.t('webapp.label.send_to_your')}
          <span onClick={() => this.loadFavoriteDrivers()}>
            {` ${favoriteDriversAmount} ${textFavDriver}`}
          </span>
        </div>
      )
    }

    return null
  }

  render() {
    const {
      currentCustomer,
      currentVehicleType,
      extraInfos,
      pickupTime,
      assignedPickupTime,
      currentStep,
      tmpSelectedDateAssignDriver,
      assignedDriver,
      stepActions,
      selectedVehicleTypeID,
      timeType,
      extraServices,
      locations,
      refNode,
      booking,
      currentPopupID,
      showDatePicker,
      checkLocations,
      bookAgainDetails,
    } = this.props
    const { currentSelected, isShowPopup } = this.state
    const enableAssignDriver = extraInfos.customer_assign_driver_toggle
    const isHideDriverPreference = isCustomerEditBooking()
    let driverName = ''
    const brandIco = extraInfos.country_code === 'PH' ? 'transportify' : 'deliveree'
    const contentSwitchTabSchedule = this.tempTabSelected === DRIVER_PREFERENCES.favorite
      ? I18n.t('webapp.new_booking.step_2.assign_driver.favorite_driver_warning')
      : I18n.t('webapp.new_booking.step_2.assign_driver.assign_driver_warning')
    const titleSwitchTabSchedule = this.tempTabSelected === DRIVER_PREFERENCES.favorite
      ? I18n.t('webapp.label.favorite_drivers')
      : I18n.t('webapp.label.assign_driver')
    const iconSwitchTabSchedule = this.tempTabSelected === DRIVER_PREFERENCES.favorite
      ? ICON_SWITCH_TAB_POPUP
      : ICON_DRIVER_PREFERENCES_FULL

    if (assignedDriver) {
      driverName = assignedDriver.fleet_partner_name || Utils.driverFirstName(assignedDriver.name)
    }
    const minMinutes = minuMumBufferMinutes(currentVehicleType, SCHEDULE, 0, true)

    driverName = driverName.length > 6 ? `${driverName.substring(0, 6)}...` : driverName

    const extraRequirementIds = AssignDriversUtils.getExtraRequirementIds(extraServices)

    return (!isHideDriverPreference
      && (
        <div>
          <div className="mr10 mt20 ml10 White-bg Radius-default">
            <div className="Title-bg Radius-Top-Default flex flex-start pt10 pb10 pl15 pr15 cur-pointer">
              <b className="default-small-font default-color mr5">
                {I18n.t('webapp.label.driver_preferences')}
              </b>
              {enableAssignDriver && (
                <DriverPreferencesPopupEye
                  currentCustomer={currentCustomer}
                  objectID={extraInfos.area_id}
                  optionalParams={{ is_popup_eye: true }}
                  popupType={I18n.t('webapp.label.driver_preferences')}
                  refNode={refNode}
                />
              )}
            </div>
            <div
              ref={(driverPreference) => { this.driverPreferences = driverPreference }}
              className="pl15 pr15 pb15 pt15 Driver-Preferences-Container flex White-bg Radius-default"
            >
              <DriverPreferenceButton
                buttonType={DRIVER_PREFERENCES.all}
                currentSelected={currentSelected}
                handleChangePreferencesType={buttonType => this.handleChangePreferencesType(buttonType)}
              />
              <DriverPreferenceButton
                buttonType={DRIVER_PREFERENCES.favorite}
                currentSelected={currentSelected}
                handleChangePreferencesType={buttonType => this.handleChangePreferencesType(buttonType)}
              />
              {enableAssignDriver && (
                <DriverPreferenceButton
                  buttonType={DRIVER_PREFERENCES.assign}
                  currentSelected={currentSelected}
                  handleChangePreferencesType={buttonType => this.handleChangePreferencesType(buttonType)}
                />
              )}
            </div>
            {!_.isEmpty(assignedDriver) && (this.renderSelectedDriverInfo(assignedDriver))}
            {this.renderFavoriteText()}
          </div>
          <ListAssignableDriversPopup
            drivers={booking.favoriteDrivers}
            goBack={this.handleGoBack}
            resetPopup={this.resetShowPopup}
            authenticationToken={currentCustomer.authentication_token}
            areaID={extraInfos.area_id}
            companyID={currentCustomer.currentCompanyId}
            assignedDriver={assignedDriver}
            countryCode={extraInfos.country_code}
            maxPickupTimeDays={extraInfos.maximum_pickup_time_days}
            pickupTime={pickupTime}
            assignedPickupTime={assignedPickupTime}
            currentStep={currentStep}
            currentSelected={currentSelected}
            isShowPopup={isShowPopup}
            tmpSelectedDateAssignDriver={tmpSelectedDateAssignDriver}
            optionRef={{
              driverContainer: this.driverPreferences,
            }}
            stepActions={stepActions}
            selectedVehicleTypeID={selectedVehicleTypeID}
            timeType={timeType}
            extraRequirementIDs={extraRequirementIds}
            extraRequirementLocationsIDs={AssignDriversUtils.getExtraRequirementsLocationsListID(locations)}
            badgeIDs={AssignDriversUtils.getBadgesListID(extraServices)}
            currentVehicleType={currentVehicleType}
            reimbursementIDs={AssignDriversUtils.getReimbursementListID(extraServices)}
            recentDriver={booking.recentDriver}
            calledAPIAfterSignIn={this.calledAPIAfterSignIn}
            getParamsForLoadFavorite={this.getParamsForLoadFavorite}
            isNeedCOD={CommonUtils.isNeedCOD(locations)}
            isNeedPOD={CommonUtils.isNeedPOD(locations, checkLocations)}
            isNewGenPod={CommonUtils.isNewGenPod(locations, checkLocations, bookAgainDetails)}
          />
          {
            currentPopupID === POPUP_ID_SWITCH_TAB_DRIVER_PREFERENCE
              ? (
                <ModalDecision
                  iconSrc={ICON_DRIVER_PREFERENCES_FULL}
                  title={I18n.t('webapp.new_booking.step_2.assign_driver.are_you_sure')}
                  content={I18n.t('webapp.new_booking.step_2.assign_driver.switch_tab_warning', { driverName })}
                  backText={I18n.t('webapp.new_booking.step_2.assign_driver.keep_driver', { driverName })}
                  confirmText={I18n.t('webapp.new_booking.step_2.assign_driver.unassign')}
                  className="switch-tab-popup"
                  handleConfirmAction={this.handleConfirmSwitchTab}
                />
              ) : null
          }
          {
            currentPopupID === POPUP_ID_SWITCH_TO_SCHEDULE
              ? (
                <ModalDecision
                  iconSrc={iconSwitchTabSchedule}
                  title={titleSwitchTabSchedule}
                  content={contentSwitchTabSchedule}
                  backText={I18n.t('webapp.new_booking.step_2.assign_driver.keep_quick')}
                  confirmText={I18n.t('webapp.label.change')}
                  className={`switch-tab-popup ${this.tempTabSelected === DRIVER_PREFERENCES.favorite ? 'switch-tab-popup-favorite' : ''}`}
                  handleConfirmAction={this.changeTabNowTimeType}
                />
              ) : null
          }
          {showDatePicker
            && (
              <BookingCalendar
                isShowOkBtn
                isShowCancelBtn
                brandIcon={brandIco}
                selectedDateTime={pickupTime}
                minMinutes={minMinutes}
                closePicker={this.closePicker}
                maximumPickupDays={extraInfos.maximum_pickup_time_days}
                modalCssClassName="PickupTime-DatePicker-Custom"
                countryCode={extraInfos.country_code}
              />
            )
          }
        </div>
      )
    )
  }
}

DriverPreferences.propTypes = {
  others: PropTypes.shape({}).isRequired,
  OthersAction: PropTypes.shape({}).isRequired,
  stepActions: PropTypes.shape({}).isRequired,
  currentCustomer: PropTypes.shape({}).isRequired,
  extraInfos: PropTypes.shape({}).isRequired,
  extraServices: PropTypes.shape({}).isRequired,
  locations: PropTypes.instanceOf(Array).isRequired,
  currentVehicleType: PropTypes.shape({}),
  assignDriverActions: PropTypes.shape({}).isRequired,
  pickupTime: PropTypes.string.isRequired,
  currentStep: PropTypes.number.isRequired,
  area: PropTypes.shape({}).isRequired,
  refNode: PropTypes.instanceOf(Object).isRequired,
  isNewDriver: PropTypes.bool,
  assignedDriver: PropTypes.shape({}),
  selectedVehicleTypeID: PropTypes.number,
  assignedPickupTime: PropTypes.string,
  tmpSelectedDateAssignDriver: PropTypes.string,
  bookAgainDetails: PropTypes.shape({}),
  timeType: PropTypes.string,
  booking: PropTypes.shape({
    driverPreferencesTab: PropTypes.string,
  }).isRequired,
  bookingActions: PropTypes.shape({}).isRequired,
  currentPopupID: PropTypes.string,
  currentPopupIDActions: PropTypes.shape({}).isRequired,
  timeTypeActions: PropTypes.shape({}).isRequired,
  calendarBookingActions: PropTypes.shape({}).isRequired,
  quickChoiceActions: PropTypes.shape({}).isRequired,
  showDatePicker: PropTypes.bool.isRequired
}

DriverPreferences.defaultProps = {
  isNewDriver: undefined,
  selectedVehicleTypeID: undefined,
  assignedPickupTime: undefined,
  tmpSelectedDateAssignDriver: undefined,
  assignedDriver: undefined,
  bookAgainDetails: undefined,
  timeType: undefined,
  currentVehicleType: undefined,
  currentPopupID: ''
}

export default connect(mapStateToProps, mapDispatchToProps)(DriverPreferences)
