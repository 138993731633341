import { API_GATEWAY } from 'constants/appConstants'
import StorageKeys from 'constants/storage-keys'
import apiClient from 'services/axiosApp'
import store from 'store/store'
import Utils from 'utils/Utils'

const areaApi = {
  getServiceArea: async (areaID) => {
    const {} = store.getState
    try {
      const res = await apiClient.get(`${API_GATEWAY}/v1/catalog/areas/${areaID}`, { ...Utils.paramToCatalog() })
      return res?.data || {}
    } catch (err) {
      throw new Error(err)
    }
  },
  getAreas: async (params) => {
    try {
      const res = await apiClient.get(`/api/v3/service_areas`, { params })
      return res?.data
    } catch (err) {
      throw new Error(err)
    }
  },
  getCurrentArea: async (params) => {
    try {
      const res = await apiClient.get('/api/v3/service_areas/current_area', { params })
      const areaId = res?.data?.id
      if (areaId) window.localStorage.setItem(StorageKeys.AREA_ID, areaId)
      return areaId || null
    } catch (err) {
      throw new Error(err)
    }
  },
  getServiceAreaLonghaulPickupZones: async (countryCode, callback) => {
    try {
      const res = await apiClient.get('/api/v3/service_areas/long_haul_pickup_zones', {
        params: { country_code: countryCode },
      })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  actionReasons: async (areaID, params, callback) => {
    try {
      const res = await apiClient.get(`/api/v3/service_areas/${areaID}/action_reasons`, {
        params,
      })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
}

export default areaApi
