import { API_GATEWAY } from 'constants/appConstants'
import { filter } from 'lodash'
import apiClient from 'services/axiosApp'
import { getDeviceId } from 'utils/cookie'
import Utils from 'utils/Utils'
const DiscountCodeAPI = {
  checkDiscountCode: async ({ discountCode, companyID, serviceTypeID, locations, bookingID, callback }) => {
    const locationAttr = filter(locations, ({ lat, lng }) => lat && lng).map((l) => ({
      latitude: l.lat,
      longitude: l.lng,
    }))
    const dataSend = {
      code: discountCode,
      locations_attributes: locationAttr,
      service_type_id: serviceTypeID,
      ...Utils.paramToCatalog().headers,
    }
    try {
      const res = await apiClient.post(`${API_GATEWAY}/v1/catalog/areas/check_discount_code`, dataSend, {
        headers: {
          'Device-Id': getDeviceId(),
          ...Utils.paramToCatalog().headers
        },
      })

      return callback(res.data)
    } catch (err) {
      throw new Error(err)
    }
  },
}

export default DiscountCodeAPI
