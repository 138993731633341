import { API_GATEWAY } from 'constants/appConstants'
import apiClient from 'services/axiosApp'
import store from 'store/store'
import Utils from 'utils/Utils'

const SubAccountAPI = {
  getSubAccountTagList: async (params, callback) => {
    try {
      const res = await apiClient.get(`${API_GATEWAY}/v1/catalog/customers/me/sub_accounts`, {
        params: params,
        headers: {
          'Device-Type': 'Web',
          ...Utils.paramToCatalog().headers,
        },
      })
      return callback(res || {})
    } catch (err) {
      throw new Error(err)
    }
  },
  getSubAccountTags: (params) =>
    apiClient.get(`${API_GATEWAY}/v1/catalog/customers/me/sub_accounts`, {
      params: params,
      headers: {
        'Device-Type': 'Web',
        ...Utils.paramToCatalog().headers,
      },
    }),
}

export default SubAccountAPI
