import { API_GATEWAY } from 'constants/appConstants'
import StorageKeys from 'constants/storage-keys'
import apiClient from 'services/axiosApp'
import store from 'store/store'
import Utils from 'utils/Utils'

const PopupAPI = {
  getAreaTypePopup: async (authenticationToken, params, callback) => {
    try {
      const areaIdLocal = window.localStorage.getItem(StorageKeys.AREA_ID)
      const res = await apiClient.get(`${API_GATEWAY}/v1/catalog/areas/${areaIdLocal}/popups`, {
        params: params,
        headers: {
          'Device-Type': 'Web',
          ...Utils.paramToCatalog().headers,
        },
      })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  getAreaTypePopupNew: (params) => {
    const areaIdLocal = window.localStorage.getItem(StorageKeys.AREA_ID)
    return apiClient.get(`${API_GATEWAY}/v1/catalog/areas/${areaIdLocal}/popups`, {
      params: params,
      headers: {
        'Device-Type': 'Web',
        ...Utils.paramToCatalog().headers,
      },
    })
  },
  getVehicleTypePopup: async (authenticationToken, params, callback) => {
    try {
      const res = await apiClient.get('/api/v3/popups/vehicle_type', {
        params,
        headers: {
          'Device-Type': 'Web',
        },
      })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  getServiceTypePopup: async (authenticationToken, params, callback) => {
    try {
      const res = await apiClient.get('/api/v3/popups/service_type', {
        params,
        headers: {
          'Device-Type': 'Web',
        },
      })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
}

export default PopupAPI
