import { API_GATEWAY } from 'constants/appConstants'
import apiClient from 'services/axiosApp'
import CommonUtils from 'utils/common'
import { getAppsFlyerUID, getDeviceId } from 'utils/cookie'
import Utils from 'utils/Utils'

const SettingAPI = {
  getCustomerSettingsApi: async (query, callback, countryCode) => {
    try {
      const res = await apiClient.get(`${API_GATEWAY}/v1/catalog/customers/settings`, {
        params: query,
        headers: {
          'Country-Code': countryCode,
          'Appsflyer-Uid': getAppsFlyerUID(),
          'App-Name': CommonUtils.appName(),
          'Device-Id': getDeviceId(),
          ...Utils.paramToCatalog().headers,
        },
      })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  updateAutomaticSettings: async (authenticationToken, params, callback) => {
    try {
      const res = await apiClient.put('api/v3/settings/automatic_booking_frequency', params, {
        headers: {
          'Device-Type': 'Web',
        },
      })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  getInitDataTrackingApi: async (params) => apiClient.get('/api/v3/settings/live_tracking', { params }),
  getFrontPageLink: (countryCode) =>
    apiClient.get(
      `${API_GATEWAY}/v1/setting/system-settings?keys=first_box_url&keys=second_box_url&keys=third_box_url`,
      {
        headers: {
          countrycode: countryCode,
        },
      }
    ),
  getSettingsCustomerByArea: (areaId, type = 'pickup_change') =>
    apiClient.get(`${API_GATEWAY}/v1/catalog/customers/settings_by_area?area_id=${areaId}&section_type=${type}`, {
      headers: {
        'Appsflyer-Uid': getAppsFlyerUID(),
        'App-Name': CommonUtils.appName(),
        'Device-Id': getDeviceId(),
        ...Utils.paramToCatalog().headers,
      },
    }),
  getFootNotesSummary: (params) =>
    apiClient.get(`${API_GATEWAY}/v1/catalog/vehicle_types/${params.vehicle_type_id}/foot_notes`, { params }),
  getFootNotesBooking: (bookingId) => apiClient.get(`/api/v3/ftl_preparation/bookings/${bookingId}`),
  getSettingBusiness: (params) => apiClient.get(`/api/v3/business`, { params }),
  updateSettingBusiness: (data, params) => apiClient.put(`/api/v3/business`, data, { params }),
}

export default SettingAPI
