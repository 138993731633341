import { API_GATEWAY } from 'constants/appConstants'
import apiClient from 'services/axiosApp'
import Utils from 'utils/Utils'

const EventNotificationAPI = {
  getCurrentEvent: async (areaId, currentCustomer) => {
    const queryParam = {
      params: {
        company_id: currentCustomer ? currentCustomer.currentCompanyId : 0,
      },
      headers: {
        'Device-Type': 'Web',
        ...Utils.paramToCatalog().headers
      },
    }
    const res = await apiClient.get(`${API_GATEWAY}/v1/catalog/areas/${areaId}/event_notifications`, queryParam)
    return res?.data
  },
}

export default EventNotificationAPI
