import { API_GATEWAY } from 'constants/appConstants'
import apiClient from 'services/axiosApp'
import store from 'store/store'
import Utils from 'utils/Utils'

const baseUrl = '/api/v3/vehicle_types'

const vehicleTypeAPIs = {
  getVehicleTypeInfo: async (vehicleTypeId) => apiClient.get(`${baseUrl}/${vehicleTypeId}`),
  getNumberOfOnlineDrivers: async (vehicleTypeId, location, callback) => {
    const params = {
      'location[lat]': location.lat && location.lat.toString().trim(),
      'location[lng]': location.lng && location.lng.toString().trim(),
    }
    try {
      const res = await apiClient.get(`${API_GATEWAY}/v1/catalog/vehicle_types/${vehicleTypeId}/driver_count`, {
        params: params,
        ...Utils.paramToCatalog(),
      })
      return callback(res?.data)
    } catch (err) {
      throw new Error(err)
    }
  },
}

export default vehicleTypeAPIs
