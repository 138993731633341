import { API_GATEWAY, DELIVEREE_PLACES_SERVICE_URL } from 'constants/appConstants'
import { filter, isUndefined } from 'lodash'
import apiClient from 'services/axiosApp'
import mapUtils from 'utils/common/map'
import * as utilsCommon from 'utils/booking/common'
import { X_USE_CASE } from 'constants/CommonConstant'
import moment from 'moment'
import store from 'store/store'
import Utils from 'utils/Utils'

const handleFilterLocations = (locations) =>
  filter(locations, (location) => {
    let verify = false
    const list = [location.latitude, location.longitude]
    if (!list.includes(undefined)) {
      verify = true
    }

    const locationTmp = [location.lat, location.lng]
    if (!locationTmp.includes(undefined)) {
      return true
    }

    return verify
  })

const getLatLng = (location, isEnableGoogleMap) => {
  const lat = isUndefined(location.marker)
    ? location.lat || location.latitude
    : mapUtils.getLatLngFromMarker(location.marker, isEnableGoogleMap).lat
  const lng = isUndefined(location.marker)
    ? location.lng || location.longitude
    : mapUtils.getLatLngFromMarker(location.marker, isEnableGoogleMap).lng
  return { lat, lng }
}

const LocationAPI = {
  getCheckLocations: async (
    locations,
    { timeType, serviceTypeID, vehicleTypeID, isSimpleData = false, pickupTime },
    { countryCode, companyId, isEnableGoogleMap },
    callback
  ) => {
    // Declare variable
    const dataSend = {
      check_cod_pod: {},
    }
    const timeTypes = {
      now: 1,
      schedule: 2,
      full_day: 3,
      long_haul: 4,
    }

    // Handle logic
    const filterLocations = handleFilterLocations(locations)

    dataSend.locations = filterLocations.map((i) => ({ lat: +i.lat, lng: +i.lng }))
    if (countryCode) {
      dataSend.country_code = countryCode
    }

    if (timeType) {
      dataSend.booking_type = timeTypes[timeType]
      dataSend.check_cod_pod.time_type = timeType
    }

    if (serviceTypeID) {
      dataSend.service_type_id = serviceTypeID
    }

    if (vehicleTypeID) {
      dataSend.vehicle_type_id = vehicleTypeID
    }

    if (companyId && timeType) {
      dataSend.check_cod_pod.company_id = companyId
    }
    if (isSimpleData) {
      dataSend.simple_data = true
    }
    if (pickupTime) {
      const formatPickupTime = moment(pickupTime).format()
      dataSend.pickup_time = formatPickupTime
    }
    if (companyId) {
      dataSend.company_id = companyId
    }
    const res = await apiClient.post(`${API_GATEWAY}/v1/catalog/locations/check_locations`, dataSend, {
      ...Utils.paramToCatalog(),
    })
    callback(res.data)
  },
  validLocationsInArea: async (locations, areaId, callback, authenticationToken, companyId, isEnableGoogleMap) => {
    let requestString = ''
    let lat
    let lng
    const validLocations = filter(locations, (location) => location.lat || location.lng)

    if (!validLocations.length) {
      // TODO: we should warning here
      return callback({})
    }

    validLocations.forEach((location, index) => {
      if (isUndefined(location.marker)) {
        lat = location.lat
        lng = location.lng
      } else {
        lat = mapUtils.getLatLngFromMarker(location.marker, isEnableGoogleMap).lat
        lng = mapUtils.getLatLngFromMarker(location.marker, isEnableGoogleMap).lng
      }
      requestString += `lat=${lat}&lng=${lng}`
      if (index !== locations.size - 1) {
        requestString += '&'
      }
    })

    requestString += `&area_id=${areaId}&company_id=${companyId}`
    try {
      const res = await apiClient.get(
        `${API_GATEWAY}/v1/catalog/areas/check_correct_area?${decodeURIComponent(requestString)}`,
        { ...Utils.paramToCatalog() }
      )
      return callback(res.data)
    } catch (err) {
      throw new Error(err)
    }
  },
  calculateCODPODFees: async (params, AuthenticationToken, callback) => {
    try {
      const res = await apiClient.post(`${API_GATEWAY}/v1/catalog/locations/calculate_cod_pod`, params, {
        ...Utils.paramToCatalog(),
      })
      return callback(res.data)
    } catch (err) {
      throw new Error(err)
    }
  },
  getGeocode: (query, xUseCase = X_USE_CASE.NEW_BOOKING) => {
    const isVn = utilsCommon.getParamFromURL('lang') === 'vi'
    return apiClient.get(`${DELIVEREE_PLACES_SERVICE_URL}/api/v2/geocode${query.latlng ? '/reverse' : ''}`, {
      params: query,
      headers: {
        Authorization: '',
        'Device-Type': 'Web',
        'x-use-case': xUseCase,
        ...(isVn && { 'Accept-Language': 'vi' }),
      },
    })
  },
  confirmLocation: async (accessToken, bookingID, dataSend, callback) => {
    try {
      const res = await apiClient.put(`/api/v3/bookings/${bookingID}/locations/confirm`, dataSend, {
        headers: {
          ...(accessToken && { Authorization: accessToken }),
        },
      })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  getPredictionLocations: async (query, xUseCase = X_USE_CASE.NEW_BOOKING) => {
    try {
      const isVn = utilsCommon.getParamFromURL('lang') === 'vi'
      const headers = {}
      if (isVn) {
        headers['Accept-Language'] = 'vi'
      }
      const res = await apiClient.get(`${DELIVEREE_PLACES_SERVICE_URL}/api/v2/place/autocomplete`, {
        params: query,
        headers: {
          ...headers,
          'x-use-case': xUseCase,
        },
      })
      return res
    } catch (err) {
      throw new Error(err)
    }
  },
  getPlaceDetailApi: (query, source) => {
    const isVn = utilsCommon.getParamFromURL('lang') === 'vi'
    return apiClient.get(`${DELIVEREE_PLACES_SERVICE_URL}/api/v2/place/details`, {
      params: query,
      headers: {
        Authorization: '',
        'x-source': source,
        ...(isVn && { 'Accept-Language': 'vi' }),
      },
    })
  },
  getRoutesDirection: (params) =>
    apiClient.post(`${DELIVEREE_PLACES_SERVICE_URL}/api/v2/routes/direction`, params, {
      headers: {
        Authorization: '',
      },
    }),
  //we can set steps = true to get encodedPolyline (for case draw route)
  getRoutesOptimize: (params) =>
    apiClient.post(`${DELIVEREE_PLACES_SERVICE_URL}/api/v2/routes/optimize`, params, {
      headers: {
        Authorization: '',
      },
    }),
}

export default LocationAPI
